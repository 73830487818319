import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { mq } from "../components/layout";
import * as colors from "../utils/colors";

const HeaderAndFooter = styled.div`
  max-width: 90rem;
  width: 100%;
  margin: 0 auto;
  display: flex;
  padding: 1rem;
  align-items: center;
`;

const headerLinkBase = css`
  color: unset;
  text-decoration: none;
`;

const secondaryHeaderLinks = css`
  margin-right: 2rem;

  display: none;
  ${mq[0]} {
    display: inline;
  }
`;

const headerLinkActiveStyle = {
  textDecoration: "underline",
  textUnderlinePosition: "under",
  textDecorationColor: colors.GRAPIC_TURQUOISE,
  textDecorationThickness: "0.2rem",
};

const Header = () => {
  const { site } = useStaticQuery(
    graphql`
      {
        site {
          siteMetadata {
            name
            appUrl
          }
        }
      }
    `
  );

  return (
    <HeaderAndFooter
      css={css`
        justify-content: space-between;
      `}
    >
      <Link
        to="/"
        css={css`
          color: unset;
          text-decoration: unset;
          display: flex;
          align-items: center;
        `}
      >
        <StaticImage
          src="../assets/logo/png/grapic-logo-noborder.png"
          alt="Grapic logo"
          width={38}
          formats={["auto", "webp", "avif"]}
          layout="fixed"
          placeholder="dominantColor"
          css={css`
            margin-right: 0.8rem;
          `}
        />
        <div>
          <h1
            css={css`
              font-size: 1.5rem;
              margin: 0;
            `}
          >
            {site.siteMetadata.name}
          </h1>
        </div>
      </Link>
      <div
        css={css`
          font-family: "Poppins", serif;
        `}
      >
        <Link
          to="/how-to/"
          activeStyle={headerLinkActiveStyle}
          partiallyActive={true}
          css={css`
            ${headerLinkBase};
            ${secondaryHeaderLinks};
          `}
        >
          How to
        </Link>
        <Link
          to="/about/"
          activeStyle={headerLinkActiveStyle}
          partiallyActive={true}
          css={css`
            ${headerLinkBase};
            ${secondaryHeaderLinks};
          `}
        >
          About us
        </Link>
        <a
          href={site.siteMetadata.appUrl}
          css={css`
            ${headerLinkBase};
            font-weight: 700;
          `}
        >
          Get started →
        </a>
      </div>
    </HeaderAndFooter>
  );
};

export default Header;
